import React from "react"
import Layout from "../../Layout/Layout"
import PositionCard from "../../position-card/positionCard"
import NewSlider from "../../NewSlider/NewSlider"
import styles from "../../../styles/careers.module.scss"
import WhiteSection from "../../whiteSection/whiteSection"
import tt from "../../../helpers/translation"
import buildResponsiveImages from "../../../helpers/buildResponsiveImages"
import ResponsiveImage from "../../ResponsiveImage/ResponsiveImage"
import SliderBanner from "../../sliderBanner/SliderBanner"

const CareersPage = ({ data, lang, seoData, urls }) => {
  const careersMap = data => {
    return data.allCareersJson.edges.map((element, index) => (
      <PositionCard
        key={index}
        name={element.node.name}
        title={element.node.title}
        sendTo={element.node.sendTo}
        textButton={tt("View role", lang)}
      />
    ))
  }

  const careersImagesDesktop = data.careersImagesDesktop.nodes.map(
    node => node.childImageSharp
  )
  const careersImagesMobile = data.careersImagesMobile.nodes.map(
    node => node.childImageSharp
  )

  return (
    <Layout
      leftContactUs
      lan={lang}
      location="careers"
      contrast={true}
      seoData={seoData}
      urls={urls}
    >
      <WhiteSection
        first
        firstTitle={tt("Join us", lang)}
        subtitle={tt(
          "Be part of an ambitious and talented team. We would love to hear from you.",
          lang
        )}
      />
      <SliderBanner
        slides={buildResponsiveImages(
          careersImagesDesktop,
          careersImagesMobile
        ).map((item, index) => {
          return <ResponsiveImage key={index} alt="" fluid={item.fluid} />
        })}
      />
      {/* The next code is commented because it didn’t respond well when
      directly loading on the phone a certain route. Should be improved in order to use it instead of current solution
due to the current need to load js to display */}
      {/* <NewSlider
        slides={buildResponsiveImages(
          careersImagesDesktop,
          careersImagesMobile
        ).map((item, index) => {
          return <ResponsiveImage key={index} alt="" fluid={item.fluid} />
        })}
        className={styles.slider}
      ></NewSlider> */}
      <WhiteSection
        title={tt("The environment", lang)}
        subtitle={tt(
          "We are a technology, design & innovation studio located in Barcelona.",
          lang
        )}
        text={
          <div>
            <p className={styles.description}>
              {tt(
                "If you are a developer looking to be part of a talented and motivated team. Contact us.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "We are developers with many years of experience and for this reason we offer the team the benefits we want for ourselves.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "Good working atmosphere, agile methodologies, mentoring by senior profiles, and policy combining work with private life. Our working day is seven hours on an intensive timetable.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt("If you like what you have read, contact us.", lang)}
            </p>
          </div>
        }
      />
      {/* <div className={`${styles.blue_section_container}`}>
        <div className={styles.text_container}>
          <h2>{tt("Careers", lang)}</h2>
          <h3>{tt("Open positions", lang)}</h3>
          <div className={styles.positions_container}>{careersMap(data)}</div>
        </div>
      </div> */}
    </Layout>
  )
}

export default CareersPage
