import { graphql } from "gatsby"
import React from "react"
import CareersPage from '../../components/pages/Careers'

const lang = 'ca'

const Careers = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_ca,
    titleSeo: seoDataJson.title_ca,
    description: seoDataJson.description_ca,
    alternateLanguage: seoDataJson.alternateLanguage_ca,
    alternateUrl: seoDataJson.alternateUrl_ca,
  }
  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "CAREERS"
  })

  return (
    <CareersPage
      lang={lang}
      data={data}
      seoData={seoData}
      urls={resultUrls}
    />
  )
}

export const pageQuery = graphql`
  query careersCaQuery {
    careersImagesDesktop: allFile(
      filter: {
        relativePath: { glob: "group/careers/desktop/*" }
        extension: { eq: "jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    careersImagesMobile: allFile(
      filter: {
        relativePath: { glob: "group/careers/mobile/*" }
        extension: { eq: "jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    seoDataJson(name: { eq: "careers" }) {
      url_ca
      title_ca
      description_ca
      alternateLanguage_ca
      alternateUrl_ca
    }
    allCareersJson(filter: { lang: { eq: "ca" } }) {
      edges {
        node {
          name
          title
          sendTo
          lang
        }
      }
    }
  }
`

export default Careers
